import { IEnvironment } from './interface/IEnvironment.interface';
import { PREFIX, SERVICES } from './path-consts/prefix.env';

export const environment: IEnvironment = {
  state: 'DEVELOPMENT',
  FE_URL: `https://tmtrav-food-admin-${PREFIX.DEV}.tma-swerp.com/`,
  FE_URL_SALE: `https://tmtrav-ecommerce-${PREFIX.DEV}.tma-swerp.com/`,
  URL_RESTAURANT: `https://tmtrav-food-${PREFIX.DEV}.tma-swerp.com/`,
  BE_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com/`,
  IFRAME_CONNECT_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com/app-connect`,
  LOGIN_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com`,
  SERVICES: SERVICES,
  APP_TITLE: 'TMTravel',
  APP_LOGO: 'TMTravel-white',
  ADMIN_HUB_URL: 'http://admin-dev.tma-swerp.com',
  IFRAME_BANNER_MANAGEMENT: `http://tmtrav-banner-${PREFIX.DEV}.tma-swerp.com/restaurant`
};
